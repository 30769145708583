html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f4f8;
}

textarea,
input,
button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  background-color: white;
  line-height: 1.5;
  margin: 0;
}
.customForm {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  border-radius: 10px;
  padding: 20px;
  background-color: #f7f7f7;
}
.customTable {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #ffffff;
}

.panel-heading {
  border-bottom: 1px solid #ebebe1;
}

.labelName {
  font-weight: bold;
}

.iconList {
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.buttonLink{
  color: white;
  text-decoration: none;
}

button {
  color: #3992ff;
  font-weight: 500;
}

textarea:hover,
input:hover,
button:hover {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px);
}

nav {
  box-shadow: 2px 2px #dddddd;
}

nav a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: pre;
  padding: 0.5rem;
  color: inherit;
  text-decoration: none;
  gap: 1rem;
}

nav a:hover {
  background: #e3e3e3;
}

nav a.active {
  border-bottom: 1px solid black;
}

nav a.pending {
  color: hsl(224, 98%, 58%);
}

.nav-tabs .nav-item button {
  color: black;
  background-color: #ffffff;
  box-shadow: none;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.nav-tabs .nav-item button.active {
  background-color: #f7f7f7;
}

.tab-content .container {
  border-top-left-radius: 0;
}

#content {
  flex: 1;
  padding: 2rem 4rem;
   width: 100%;
}

#content.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

.title {
  background-color:#ffffff;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor {
  min-height: 18em;
  max-height: 18em;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.backofficeIdLabel {
  color: red;
}

.buttonBar {
  padding: 0;
}

.message-log input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.message-log textarea {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.location-devices-modal {
  width: 80vw;
  max-width: 80vw;
}